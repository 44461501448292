<template>
  <div>
    <el-dialog :title="type=='events'?'参赛人员':type=='train'?'活动人员':'添加人员'" :visible.sync="dialogTableVisible" @close="dialogClose">
      <el-form :inline="true" :model="queryParams" label-width="70px">
        <el-form-item label="姓名:" prop="name">
          <el-input type="age" v-model="queryParams.name" placeholder="请输入姓名" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="证件号:" prop="certificatesNumber">
          <el-input type="age" v-model="queryParams.certificatesNumber" placeholder="请输入证件号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="mobilePhone">
          <el-input type="age" v-model="queryParams.mobilePhone" placeholder="请输入手机号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="submitForm()">搜索</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="participant" :key="tableKey" :data="participantData" border v-loading="participantLoading" @selection-change="participantChange"    >
        <el-table-column fixed type="selection" width="55" align="center" :selectable="selectable"/>
        <el-table-column fixed type="index" label="序号" width="70" align="center" />
        <el-table-column prop="name" label="姓名" width="120" align="center">
          <template slot-scope="scope">
            <el-input v-if="scope.row.edit" v-model="scope.row.name"></el-input>
            <div v-else>{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="gender" label="性别" width="120" align="center">
          <template slot-scope="scope">
            <el-select v-if="scope.row.edit" v-model="scope.row.gender" placeholder="请选择">
              <el-option v-for="item in genderOps" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <div v-else>{{ scope.row.gender == 1 ? "男" : scope.row.gender == 2 ? "女" : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="certificatesNumber" label="证件号" align="center" >
          <template slot-scope="scope">
            <el-input v-if="scope.row.edit" v-model="scope.row.certificatesNumber" maxlength="18"></el-input>
            <div v-else>{{ scope.row.certificatesNumber }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="mobilePhone" label="手机号" width="150" align="center">
          <template slot-scope="scope">
            <el-input v-if="scope.row.edit" v-model="scope.row.mobilePhone" maxlength="11"></el-input>
            <div v-else>{{ scope.row.mobilePhone }}</div>
          </template>
        </el-table-column>
<!--        <el-table-column prop="category" label="人员类型" width="120" align="center" v-if="isCategory">-->
<!--          <template slot-scope="scope">-->
<!--            <el-select v-if="scope.row.edit" v-model="scope.row.category" placeholder="请选择">-->
<!--              <el-option v-for="item in categoryList" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
<!--            </el-select>-->
<!--            <div v-else>-->
<!--              <span v-if="scope.row.category == 2">领队</span>-->
<!--              <span v-else-if="scope.row.category == 3">教练</span>-->
<!--              <span v-else-if="scope.row.category == 4">工作人员</span>-->
<!--              <span v-else-if="scope.row.category == 5">其他</span>-->
<!--              <span v-else></span>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.edit" @click="editPersonnel(scope.row)">保存</el-button>
            <el-button type="text" v-else @click="editPersonnel(scope.row, 'edit')">编辑</el-button>
            <el-button type="text" class="btn-delCertResume" @click="delPersonnel(scope.row.id,scope.$index)" >删除</el-button>
            <el-button type="text"  v-if="scope.row.edit" class="btn-cancel" @click="cancelPersonnel(scope.row)" >取消</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="page-bar" background
          @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryParams.currentPage" :page-sizes="[10, 20, 50, 100]"
          :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addParticipant" >添加人员</el-button>
        <el-button type="success" size="small" @click="importFile">导入名单</el-button>
        <el-button type="primary" size="small" @click="selectParticipant">选择人员</el-button>
      </div>
    </el-dialog>
    <Import v-if="leadingVisible" ref="Leading" @leading="leadingSubmit" :t="t" :fileUrl="fileUrl" :acceptFile="'.xls,.xlsx'" typeStr="train" @close="closeLeading"/>
  </div>
</template>

<script>
import {
  getPersonnel,
  editPersonnel,
  delPersonnel,
} from "@/api/events";
import Import from "../../components/Import";
import {trainTrainPersonnelDel, trainTrainPersonnelEdit, trainTrainPersonnelList} from "../../api/train";
export default {
  name: "addPeople.vue",
  components: {
    Import
  },
  data(){
    return{
      leadingVisible: false,
      t: '/api/train/train/excel/export?type=1',
      fileUrl: process.env.VUE_APP_API_URL + '/api/train/train/excel/import?type=1',
      dialogTableVisible:false,
      participantData:[],
      participantLoading:false,
      tableKey:'',
      genderOps: [
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ],
      categoryList:[
        { label: "领队", value: 2 },
        { label: "教练", value: 3 },
        { label: "工作人员", value: 4 },
        { label: "其他", value: 5 },
      ],
      selectData:[],
      tableKey1:'',
      competitionItemFeeVos:[],
      isCategory:false,
      btnSelect:true,
      headers: { Authorization: window.localStorage.token },
      queryParams: {
        trainId:'',
        name: '',
        certificatesNumber: '',
        mobilePhone: '',
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      type:""
    }
  },
  methods:{
    init(competitionItemFeeVos,isCategory,btnSelect,type,trainId){
      this.queryParams = {
        trainId:trainId,
        name: '',
        certificatesNumber: '',
        mobilePhone: '',
        currentPage: 1,
        pageSize: 10
      }
      this.dialogTableVisible = true
      this.isCategory = isCategory
      this.btnSelect = btnSelect
      this.competitionItemFeeVos = competitionItemFeeVos
      this.type = type
      if(type == 'events'){
        this.getPersonnel()
      }else if(type == 'train'){
        this.getTrainTrainPersonnelList()
      }
    },
    //查询
    submitForm(){
      if(this.type == 'events'){
        this.getPersonnel()
      }else if(this.type == 'train'){
        this.getTrainTrainPersonnelList()
      }
    },
    //重置
    resetForm(){
      this.queryParams = {
          name: '',
          certificatesNumber: '',
          mobilePhone: '',
          currentPage: 1,
          pageSize: 10
      }
      if(this.type == 'events'){
        this.getPersonnel()
      }else if(this.type == 'train'){
        this.getTrainTrainPersonnelList()
      }
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      if(this.type == 'events'){
        this.getPersonnel()
      }else if(this.type == 'train'){
        this.getTrainTrainPersonnelList()
      }
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      if(this.type == 'events'){
        this.getPersonnel()
      }else if(this.type == 'train'){
        this.getTrainTrainPersonnelList()
      }
    },
    // 获取参赛人员列表
    getPersonnel() {
      this.participantLoading = true;
      getPersonnel(this.queryParams).then((res) => {
        if(res.code == 200){
          res.data.list.forEach(item=>{
            if(this.isCategory){
              item.category = 2
            }else {
              item.category = 1
            }
          })
          this.participantData = res.data.list;
          this.total = res.data.pagination.total
          this.participantLoading = false;
        }else {
          this.$message({message: res.msg,type: "warning",})
          this.participantLoading = false;
        }
      });
    },
    //获取培训人员列表
    getTrainTrainPersonnelList(){
      this.participantLoading = true;
      trainTrainPersonnelList(this.queryParams).then(res=>{
        if(res.code == 200){
          this.participantData = res.data.list;
          this.total = res.data.pagination?res.data.pagination.total:0
          this.participantLoading = false;
        }else {
          this.$message({message: res.msg,type: "warning",})
          this.participantLoading = false;
        }
      })
    },
    //添加人员按钮
    addParticipant() {
      this.participantData.push({
        certificatesNumber: "",
        gender: "",
        mobilePhone: "",
        name: "",
        edit: true,
      })
    },
    // 添加/编辑参赛人员
    editPersonnel(row, type) {
      if (type == "edit") {
        this.participantData.forEach((el) => {
          if (el.id == row.id) {
            el.edit = true
          }
        });
        this.tableKey = Math.random()
        return
      }
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/    //11位手机号码正则
      let reg_idNumber = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (!row.name) {
        return this.$message({message: "请输入姓名",type: "warning",})
      }
      if (!row.gender) {
        return this.$message({message: "请选择性别",type: "warning",})
      }
      if (!row.certificatesNumber) {
        return this.$message({message: "请输入证件号",type: "warning",})
      }
      if(!reg_idNumber.test(row.certificatesNumber)){
        return this.$message({message: "请输入正确的证件号",type: "warning",})
      }
      if (!row.mobilePhone) {
        return this.$message({message: "请输入手机号",type: "warning",})
      }
      if(!reg_tel.test(row.mobilePhone)){
        return this.$message({message: "请输入正确的手机号",type: "warning",})
      }
      this.participantLoading = true
      if(this.type == 'events'){
        editPersonnel(row).then((res) => {
          if (res.code == 200) {
            this.$message({message: res.msg,type: "success",})
            if (row.edit) this.$delete(row, "edit")
            if(!this.isCategory){
              this.getPersonnel(1)
            }
          }else {
            this.$message({message: res.msg,type: "error",})
          }
          this.participantLoading = false
        }).catch(err=>{
          this.participantLoading = false
        })
      }else if(this.type == 'train'){
        trainTrainPersonnelEdit(row).then((res) => {
          if (res.code == 200) {
            this.$message({message: res.msg,type: "success",})
            if (row.edit) this.$delete(row, "edit")
            this.getTrainTrainPersonnelList()
          }else {
            this.$message({message: res.msg,type: "error",})
          }
          this.participantLoading = false
        }).catch(err=>{
          this.participantLoading = false
        })
      }

    },
    // 删除参赛人员
    delPersonnel(id,index) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if(id){
          this.participantLoading = true
          if(this.type == 'events'){
            delPersonnel(id).then((res) => {
              if (res.code == 200) {
                this.$message({ message: res.msg, type: "success",});
                this.getPersonnel(1);
              }
              this.participantLoading = false
            })
          }else if(this.type == 'train'){
            trainTrainPersonnelDel(id).then((res) => {
              if (res.code == 200) {
                this.$message({ message: res.msg, type: "success",});
                this.getTrainTrainPersonnelList();
              }
              this.participantLoading = false
            })
          }

        }else {
          this.participantData.splice(index,1)
        }
      }).catch(() => {
        this.$message({ type: "info", message: "已取消删除",})
      })
    },
    //取消
    cancelPersonnel(row){
      this.$delete(row, "edit")
    },
    // 勾选参赛人员
    participantChange(val) {
      this.selectData = val;
    },
    //条件选择，没保存的参赛人员不能选择
    selectable(row,index){
      if(this.participantData[index].edit){
        return false
      }else {
        return true
      }
    },
    // 选择人员
    selectParticipant() {
      if (this.selectData.length < 1) {
        return this.$message({message: "请选择参赛人员！",type: "warning",})
      }
      for (let i = 0; i < this.selectData.length; i++) {
        if(!this.selectData[i].name){
          return this.$message({message: "请输入第"+(i+1)+"个参赛人员的姓名！",type: "warning"});
        }else if (!this.selectData[i].gender){
          return this.$message({message: "请选择第"+(i+1)+"个参赛人员的性别！",type: "warning"});
        }
      }

      this.tableKey1 = Math.random();
      this.$emit('refreshDataList',this.selectData,this.isCategory)
      this.dialogTableVisible = false;
    },
    dialogClose(){
      this.$emit('refreshClose',this.participantData)
    },
    beforeUpload(res, file){
      console.log(res)
      // const isXls = res.type === ' application/vnd.ms-excel' ? true : res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      // const isLt10M = res.size / 1024 / 1024 < 10
      // if (!isXls) {
      //   this.$message.error('上传文件格式错误!')
      // }
      // if (!isLt10M) {
      //   this.$message.error('上传文件不能超过 10MB!')
      // }``
      // return isXls && isLt10M
    },
    handleSuccess(res, file){
      this.participantLoading = true
      const formData = new FormData()
      formData.append('file', file.raw)
      this.files = formData
      this.$message({
        message: res.msg,
        type: 'success',
        duration: 1500,
        onClose: () => {
          this.visible = false
          this.$emit('leading', this.files)
          if(this.type == 'events'){
            this.getPersonnel()
          }else if(this.type == 'train'){
            this.getTrainTrainPersonnelList()
          }
          // this.getPersonnel()
        }
      })
    },
    importFile(){
      this.leadingVisible = true;
      this.$nextTick(() => {
        this.$refs.Leading.init();
      });
    },
    leadingSubmit(){
      // this.getPersonnel()
      if(this.type == 'events'){
        this.getPersonnel()
      }else if(this.type == 'train'){
        this.getTrainTrainPersonnelList()
      }
    },
    closeLeading(){
      this.leadingVisible = false;
    },
  }
}
</script>

<style scoped lang="scss">
  /deep/ .el-dialog{
    margin-top: 10vh!important;
    width: 1060px!important;
    max-height: 800px!important;
    overflow-y: auto;
  }
  .btn-delCertResume {
    color: #f56c6c;
  }
  .btn-cancel{
    color:#E6A23C;
  }
  .upload-demo{
    display: inline-block;
    margin-left: 10px;
  }
  ::v-deep .page-bar{
    margin-bottom: 0px;
    margin-top: 10px;
  }
  ::v-deep .el-table td{
    padding: 2px 0;
  }
  ///deep/ .el-input__inner{
  //  text-align: center!important;
  //}
</style>