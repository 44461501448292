<template>
  <div class="wrapper-content">
    <el-steps :active="active" class="steps-bar" align-center>
      <el-step title="添加报名"></el-step>
      <el-step title="确认报名信息"></el-step>
      <el-step title="支付费用"></el-step>
      <el-step title="报名成功"></el-step>
    </el-steps>
    <div class="steps-content">
      <div v-if="active == 0" class="steps-content-1">
        <div class="content-bg1">
          <el-tabs v-model="tabNum" class="detail-tab" @tab-click="handleClick">
            <el-tab-pane label="按项目报名" name="1">
              <div>
                <el-table
                  ref="multipleTable"
                  :key="tableKey1"
                  :data="competitionItemFeeVos"
                  border
                >
                  <el-table-column
                    fixed
                    type="index"
                    label="序号"
                    width="70"
                    align="center"
                  />
                  <el-table-column
                    fixed
                    prop="itemValName"
                    label="比赛项目"
                    align="center"
                    width="150"
                  />
                  <el-table-column
                    fixed
                    label="性别组"
                    align="center"
                    width="80"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.gender == 1
                          ? "男子"
                          : scope.row.gender == 2
                          ? "女子"
                          : scope.row.gender == 3
                          ? "混合"
                          : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column fixed label="类型" align="center" width="80">
                    <template slot-scope="scope">
                      {{
                        scope.row.type == 1
                          ? "个人"
                          : scope.row.type == 2
                          ? "双打"
                          : scope.row.type == 3
                          ? "团体"
                          : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    fixed
                    label="报名费"
                    align="center"
                    width="80"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.collectFee == 1 ? scope.row.totalFee : "免费"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column fixed label="参赛人员" header-align="center">
                    <template slot-scope="scope">
                      <span
                        v-for="tag in scope.row.competitionSignCoachForms"
                        :key="tag.id"
                      >
                        <el-tag
                          closable
                          type="info"
                          effect="plain"
                          style="margin: 4px"
                          @close="handleClose(tag, scope.$index)"
                          >{{ tag.name }}</el-tag
                        >
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column fixed label="人数" align="center" width="80">
                    <template slot-scope="scope">
                      {{
                        scope.row.competitionSignCoachForms &&
                        scope.row.competitionSignCoachForms.length
                          ? scope.row.competitionSignCoachForms.length
                          : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    fixed
                    label="操作"
                    width="100"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        @click="addPersonnel(scope.row.id, false, true)"
                        >添加人员</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="按人员报名" name="2">
              <div>
                <el-form :inline="true" :model="queryParams" label-width="80px">
                  <el-form-item
                    label="姓名:"
                    prop="name"
                    style="margin-bottom: 0"
                  >
                    <el-input
                      type="age"
                      v-model="queryParams.name"
                      placeholder="请输入姓名"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="证件号:"
                    prop="certificatesNumber"
                    style="margin-bottom: 0"
                  >
                    <el-input
                      type="age"
                      v-model="queryParams.certificatesNumber"
                      placeholder="请输入证件号"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="手机号:"
                    prop="mobilePhone"
                    style="margin-bottom: 0"
                  >
                    <el-input
                      type="age"
                      v-model="queryParams.mobilePhone"
                      placeholder="请输入手机号"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="margin-bottom: 0">
                    <el-button type="primary" plain @click="submitForm()"
                      >搜索</el-button
                    >
                    <el-button @click="resetForm()">重置</el-button>
                    <el-button
                      style="margin-bottom: 10px"
                      type="primary"
                      @click="addPersonnel('', false, false)"
                      >添加人员</el-button
                    >
                  </el-form-item>
                </el-form>
                <el-table
                  ref="multipleTable2"
                  :key="tableKey2"
                  :data="participantData"
                  border
                >
                  <el-table-column
                    fixed
                    type="index"
                    label="序号"
                    width="70"
                    align="center"
                  />
                  <el-table-column
                    fixed
                    prop="name"
                    label="姓名"
                    width="150"
                    align="center"
                  />
                  <el-table-column
                    fixed
                    prop="certificatesNumber"
                    label="证件号"
                    width="200"
                    align="center"
                  />
                  <el-table-column
                    fixed
                    prop="mobilePhone"
                    label="手机号"
                    width="120"
                    align="center"
                  />
                  <el-table-column fixed label="参赛项目" align="center">
                    <template slot-scope="scope">
                      <span
                        v-for="(item, index) in scope.row.itemList"
                        :key="index"
                      >
                        <el-tag
                          closable
                          type="info"
                          effect="plain"
                          style="margin: 0 4px"
                          @close="handleCloseItem(scope.$index, index)"
                          >{{ item.itemValName }}</el-tag
                        >
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    fixed
                    label="操作"
                    width="100"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-button type="text" @click="addItem(scope.row.id)"
                        >添加项目</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  class="page-bar"
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryParams.currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="queryParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="flex">
            <div class="flex1">
              <el-input
                v-model="teamName"
                placeholder="输入您的参赛队名称"
                style="width: 280px"
              ></el-input>
              <span style="font-size: 14px; color: #666; margin-left: 5px"
                >(如果您是单位队伍负责人，请输入您所在的单位或队伍名称，如XXX学校、XXX村民运动会)</span
              >
            </div>
          </div>
        </div>

        <div class="content-bg2">
          <el-table :data="totalData" border :highlight-current-row="true">
            <el-table-column
              fixed
              type="index"
              label="序号"
              width="70"
              align="center"
            />
            <el-table-column
              fixed
              prop="category"
              label="人员类型"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.category == 1">运动员</span>
                <span v-else-if="scope.row.category == 2">领队</span>
                <span v-else-if="scope.row.category == 3">教练</span>
                <span v-else-if="scope.row.category == 4">工作人员</span>
                <span v-else>其他</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed
              prop="name"
              label="姓名"
              width="150"
              align="center"
            />
            <el-table-column
              fixed
              prop="gender"
              label="性别组"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.gender == 1
                    ? "男"
                    : scope.row.gender == 2
                    ? "女"
                    : ""
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed
              prop="itemValName"
              label="报名项目"
              align="center"
            />
            <el-table-column
              fixed
              prop="type"
              label="类型"
              width="80"
              align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.type == 1
                    ? "个人"
                    : scope.row.type == 2
                    ? "双打"
                    : scope.row.type == 3
                    ? "团体"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column fixed label="操作" width="100" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="btn-delCertResume"
                  @click="
                    delPeople(
                      scope.row,
                      scope.row.index,
                      scope.$index,
                      scope.row.otherIndex
                    )
                  "
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div v-show="active == 1" class="steps-content-2">
        <div class="content-bg1">
          <div class="step-title">活动信息</div>
          <div class="step-detail">
            <div class="step-content-info">
              <h2>
                {{ dataInfo.name }}
                <el-tag
                  v-if="dataInfo.status == 5"
                  type="success"
                  effect="dark"
                  size="mini"
                  >筹备中</el-tag
                >
                <el-tag
                  v-if="dataInfo.status == 7"
                  type="danger"
                  effect="dark"
                  size="mini"
                  >报名中</el-tag
                >
                <el-tag
                  v-if="dataInfo.status == 8"
                  type="info"
                  effect="dark"
                  size="mini"
                  >报名已结束</el-tag
                >
                <el-tag v-if="dataInfo.status == 9" effect="dark" size="mini"
                  >活动中</el-tag
                >
                <el-tag
                  v-if="dataInfo.status == 10"
                  type="info"
                  effect="dark"
                  size="mini"
                  >活动已结束</el-tag
                >
              </h2>
              <p>
                <span class="name">培训时间：</span>
                <span class="value"
                  >{{ formatDateYMD(dataInfo.hostStartTime) }}-{{
                    formatDateYMD(dataInfo.hostEndTime)
                  }}</span
                >
              </p>
              <p>
                <span class="name">培训地点：</span>
                <span class="value"
                  >{{ dataInfo.province }}{{ dataInfo.city }}{{ dataInfo.area
                  }}{{ dataInfo.address }}</span
                >
              </p>
              <p>
                <span class="name">培训方式：</span>
                <span class="value" v-if="dataInfo.model == 1">线下培训</span>
                <span class="value" v-else-if="dataInfo.model == 2">{{
                  dataInfo.onlineModel == 1 ? "视频培训" : "直播培训"
                }}</span>
              </p>
              <p>
                <span class="name">报名费用：</span>
                <span v-if="dataInfo.collectFee == 1" class="value danger"
                  >¥<i class="fz22">{{ dataInfo.totalFee }}</i></span
                >
                <span class="btn-delCertResume" v-else
                  ><i class="fz22">免费</i></span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="content-bg2">
          <div class="step-title">报名信息</div>
          <el-table
            :data="enrollInfo"
            class="tab_apply"
            style="margin-top: 20px"
          >
            <!--          <el-table-column type="selection" width="55" />-->
            <el-table-column
              fixed
              type="index"
              label="序号"
              width="70"
              align="center"
            />
            <el-table-column
              prop="itemValName"
              label="报名项目"
              width="200"
              align="center"
            />
            <el-table-column
              prop="gender"
              label="性别组"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.gender == 1
                    ? "男子"
                    : scope.row.gender == 2
                    ? "女子"
                    : scope.row.gender == 3
                    ? "混合"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="项目类型"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.type == 1
                    ? "个人"
                    : scope.row.type == 2
                    ? "双打"
                    : scope.row.type == 3
                    ? "团体"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="feeRule"
              label="收费规则"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.feeRule == 1 ? "按人数" : "按项目" }}
              </template>
            </el-table-column>
            <el-table-column prop="itemNames" label="报名人员" align="center">
              <template slot-scope="scope">
                <span v-for="(tag, index) in scope.row.competitionSignCoachForms" :key="tag.id">
                  <span>{{ tag.name }}<i v-if=" index < scope.row.competitionSignCoachForms.length - 1">、</i><i v-else></i>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="signFee" label="原价(元)" width="100" align="center"/>
            <el-table-column label="实收(元)" width="150" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.feeRule == 2">{{ isMember ? scope.row.memberFee : scope.row.signFee }}</span>
                <span v-else>{{ (isMember ? scope.row.memberFee : scope.row.signFee) * scope.row.competitionSignCoachForms.length }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="content-bg1" style="margin-top: 20px; margin-bottom: 0">
          <div class="step-title">费用信息</div>
          <div class="feeBox">
            <div class="name">按人数收费：<span class="num">{{ peopleFee.num }}</span>人共<span class="fee">{{ peopleFee.fee }}元</span></div>
            <div class="name">按项目收费：<span class="num">{{ itemFee.num }}</span>人共<span class="fee">{{ itemFee.fee }}元</span></div>
            <div class="name total">费用合计：<span class="fee">{{ (peopleFee.fee * 100 + itemFee.fee * 100) / 100 }}元</span></div>
          </div>
        </div>
        <Pay v-if="payVisible" ref="Pay" />
      </div>
      <div v-if="active == 4" class="steps-content-4">
        <pay-result :payResult="payResult" :url="`/train-detail/${gItem.id}`" />
      </div>
    </div>
    <div class="btn-steps" v-if="active == 0 || active == 1">
      <el-button v-if="active == 0" @click="goBack">取消</el-button>
      <el-button v-if="active != 0" @click="prev">上一步</el-button>
      <el-button
        :disabled="nextchecked"
        type="primary"
        @click="next"
        :loading="btnLoading"
        >下一步</el-button
      >
    </div>
    <AddPeople
      v-show="addPeopleVisible"
      ref="AddPeople"
      @close="addPeopleVisible = false"
      @refreshDataList="addPeopleData"
    />
    <AddItem
      v-show="addItemVisible"
      ref="AddItem"
      @close="addItemVisible = false"
      @refreshDataList="addItemData"
    />
  </div>
</template>

<script>
import payResult from "@/pages/payment/result.vue";
import AddPeople from "./addPeople";
import Pay from "@/pages/payment/pay.vue";
import {
  trainTrainPersonnelConfirmSign,
  trainTrainInfo,
  trainTrainPersonnelList,
  trainVerify,
} from "@/api/train.js";
import AddItem from "./addItem";
import { memberIsMember } from "../../api/partners";
import {trainPersonnelApplyOrder} from "../../api/train";
export default {
  name: "train-signup",
  components: {
    payResult,
    AddPeople,
    Pay,
    AddItem,
  },
  data() {
    return {
      tabNum: "1",
      active: 0,
      checked: false,
      checked2: false,
      bigDialogTitle: "",
      bigDialogVisible: false,
      addPersonal: false,
      multipleSelection: [],
      multipleSelection2: [],
      tableData: [],
      nextchecked: true,
      // 添加人员
      personalForm: { name: "", sex: "", idNumber: "", phone: "", userId: "" },
      personalRules: {
        name: [
          { required: true, message: "请输入参赛人员姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        idNumber: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 7, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
      },

      // 添加项目
      queryUnitForm: {
        title: "",
      },
      activeNames: ["1"],
      checkList: [],
      gItem: {},
      prices: "",
      userInfo: {},
      // 第二步
      tableData2: [],
      applyFee: 0,
      discount: 0,
      amountPayable: 0,
      ids: [],
      orderNumber: "",
      payResult: "",
      applytime: 0,
      restaurants: [],
      ApplyDataListed: [],
      itemSetting: "",
      queryParams: {
        trainId: this.$route.query.id,
        name: "",
        certificatesNumber: "",
        mobilePhone: "",
        currentPage: 1,
        pageSize: 10,
      },
      teamName: "",
      addPeopleVisible: "",
      trainItemFeeVos: [],
      trainItemFeeVosId: [],
      payVisible: false,
      btnLoading: false,
      dataInfo: {},
      tableKey1: "",
      tableKey2: "",
      competitionItemFeeVos: [],
      participantData: [],
      total: 0,
      totalData: [],
      otherPeople: [],
      enrollInfo: [],
      itemFee: { num: 0, fee: 0 },
      peopleFee: { num: 0, fee: 0 },
      addItemVisible: false,
      trainId: "",
      isMember: false, //true表示会员，false表示不是会员
      isPay:false,
      flag:false,
      isClickPay:false,
      orderNo:''
    };
  },
  mounted() {},
  created() {
    // this.trainItemFeeVosId = []
    // this.gItem = JSON.parse(this.$route.query.trainInfo) || "";
    // this.trainItemFeeVos = this.gItem.trainItemFeeVos
    // this.trainItemFeeVos.forEach(item=>{
    //   this.trainItemFeeVosId.push(item.id)
    // })
    // this.itemSetting = this.gItem.itemSetting
    this.trainId = this.$route.query.id;
    this.getTrainTrainInfo(this.trainId);
    this.getTrainTrainPersonnelList();
  },
  methods: {
    submitForm() {
      this.getTrainTrainPersonnelList();
    },
    //重置
    resetForm() {
      this.queryParams = {
        name: "",
        certificatesNumber: "",
        mobilePhone: "",
        currentPage: 1,
        pageSize: 10,
      };
      this.getTrainTrainPersonnelList();
    },
    //获取活动详情
    getTrainTrainInfo(id) {
      trainTrainInfo(id).then((res) => {
        if (res.code == 200) {
          this.dataInfo = res.data;
          this.competitionItemFeeVos = res.data.trainItemFeeVos;
          this.$nextTick(() => {
            this.getMemberIsMember(res.data.tenantId);
          });
        }
      });
    },
    //查询当前用户是否为会员
    getMemberIsMember(tenantId) {
      let userId = window.localStorage.getItem("user")&&JSON.parse(window.localStorage.getItem("user")).userInfo.userId || "";
      memberIsMember(tenantId, userId).then((res) => {
        if (res.code == 200) {
          this.isMember = res.data;
        }
      });
    },
    //获取人员列表
    getTrainTrainPersonnelList() {
      this.participantLoading = true;
      trainTrainPersonnelList(this.queryParams).then((res) => {
        res.data.list.forEach((item) => {
          item.category = 1;
        });
        this.participantData = res.data.list;
        this.total = res.data.pagination.total;
        this.participantLoading = false;
        this.tableKey2 = Math.random();
      });
    },
    handleClick(tab) {
      this.tabNum = tab.name;
      this.nextchecked = true;
      if (tab.label != this.tabLabel) {
        this.totalData = [];
        this.otherPeople = [];
        this.tabLabel = tab.label;
        this.competitionItemFeeVos.forEach((item) => {
          item.competitionSignCoachForms = [];
        });
        this.participantData.forEach((item) => {
          this.$delete(item, "itemList");
        });
      }
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getPersonnel();
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.getPersonnel();
    },
    // 添加人员
    addPersonnel(id, isCategory, btnSelect) {
      this.isGetPeople = btnSelect;
      this.addId = id;
      this.addPeopleVisible = true;
      this.$nextTick(() => {
        this.$refs.AddPeople.init(
          this.competitionItemFeeVos,
          isCategory,
          btnSelect,
          "train",
          this.trainId
        );
      });
    },
    //选择项目
    addItem(id) {
      this.addItemId = id;
      this.addItemVisible = true;
      this.$nextTick(() => {
        this.$refs.AddItem.init(this.competitionItemFeeVos);
      });
    },
    //选择项目回显
    addItemData(data) {
      this.participantData.forEach((item, index) => {
        if (item.id == this.addItemId) {
          let arr = [];
          if (this.participantData[index].itemList) {
            arr = this.participantData[index].itemList.concat(data);
          } else {
            arr = data;
          }
          arr = this.fn1(arr);
          this.$set(this.participantData[index], "itemList", arr);
        }
      });
      this.$nextTick(() => {
        this.returnPeopleItem(2);
      });
    },
    // 删除参赛人员
    handleClose(tag, index) {
      this.$confirm("您确定要删除该参赛人员吗？", "提示", {
        type: "warning",
      }).then(() => {
        let competitionSignCoachForms =
            this.competitionItemFeeVos[index].competitionSignCoachForms;
        competitionSignCoachForms.splice(
            competitionSignCoachForms.indexOf(tag),
            1
        );
        this.tableKey1 = Math.random();
        this.returnPeopleItem(1);
      });
    },
    //选择人员回显
    addPeopleData(data, isCategory) {
      if (isCategory) {
        let arr = this.otherPeople.concat(data);
        this.otherPeople = this.fn1(arr);
      } else {
        this.competitionItemFeeVos.forEach((el, index) => {
          if (el.id == this.addId) {
            let arr = [];
            if (this.competitionItemFeeVos[index].competitionSignCoachForms) {
              arr =
                this.competitionItemFeeVos[
                  index
                ].competitionSignCoachForms.concat(data);
            } else {
              arr = data;
            }
            arr = this.fn1(arr);
            this.$set(
              this.competitionItemFeeVos[index],
              "competitionSignCoachForms",
              arr
            );
          }
        });
      }
      this.$nextTick(() => {
        this.returnPeopleItem(this.tabNum);
      });
    },
    //删除项目
    handleCloseItem(rowIndex, index) {
      this.$confirm("您确定要删除该项目吗？", "提示", {
        type: "warning",
      }).then(() => {
        this.participantData[rowIndex].itemList.splice(index, 1);
        this.returnPeopleItem(2);
      });
    },
    //显示报名人员的报项和类型列表
    returnPeopleItem(tab) {
      this.totalData = [];
      if (tab == 1) {
        this.competitionItemFeeVos.forEach((item, index) => {
          if (item.competitionSignCoachForms) {
            item.competitionSignCoachForms.forEach((dates) => {
              this.totalData.push({
                certificatesNumber: dates.certificatesNumber,
                gender: dates.gender,
                id: dates.id,
                mobilePhone: dates.mobilePhone,
                name: dates.name,
                category: 1,
                itemValName: item.itemValName,
                type: item.type,
                index: index,
                itemId: item.id,
              });
            });
          }
        });
      } else if (tab == 2) {
        this.participantData.forEach((item, index) => {
          if (item.itemList) {
            item.itemList.forEach((dates) => {
              this.totalData.push({
                certificatesNumber: item.certificatesNumber,
                gender: item.gender,
                id: item.id,
                mobilePhone: item.mobilePhone,
                name: item.name,
                category: item.category,
                itemValName: dates.itemValName,
                type: dates.type,
                itemId: dates.id,
                index: index,
              });
            });
          }
        });
      }
      if (this.otherPeople.length > 0) {
        this.otherPeople.forEach((item, index) => {
          this.totalData.push({
            certificatesNumber: item.certificatesNumber,
            gender: item.gender,
            id: item.id,
            mobilePhone: item.mobilePhone,
            name: item.name,
            category: item.category,
            itemValName: "",
            type: "",
            otherIndex: index,
          });
        });
      }
      this.isNext();
    },
    //判断是否可以点击下一步，注必须要有运动员才能进行下一步
    isNext() {
      if (this.totalData.length <= 0) {
        return (this.nextchecked = true);
      }
      for (let i = 0; i < this.totalData.length; i++) {
        if (this.totalData[i].category == 1) {
          return (this.nextchecked = false);
        } else {
          return (this.nextchecked = true);
        }
      }
    },
    //汇总报名人员和费用
    collectEnroll() {
      this.otherPeopleLD = [];
      this.otherPeopleJL = [];
      this.otherPeopleGZRY = [];
      this.otherPeopleQT = [];
      this.enrollInfo = [];
      if (this.tabNum == 1) {
        this.competitionItemFeeVos.forEach((item) => {
          if (
            item.competitionSignCoachForms &&
            item.competitionSignCoachForms.length > 0
          ) {
            this.enrollInfo.push({
              collectFee: item.collectFee,
              competitionSignCoachForms: item.competitionSignCoachForms,
              feeRule: item.feeRule,
              gender: item.gender,
              id: item.id,
              itemValName: item.itemValName,
              memberFee: item.memberFee,
              signFee: item.totalFee,
              sort: item.sort,
              type: item.type,
            });
            this.feeTotal();
          }
        });
      } else if (this.tabNum == 2) {
        this.competitionItemFeeVos.forEach((item, index) => {
          this.competitionItemFeeVos[index].competitionSignCoachForms = [];
          this.participantData.forEach((dates) => {
            if (dates.itemList && dates.itemList.length > 0) {
              dates.itemList.forEach((ele) => {
                if (item.id == ele.id) {
                  this.competitionItemFeeVos[
                    index
                  ].competitionSignCoachForms.push({
                    category: 1,
                    certificatesNumber: dates.certificatesNumber,
                    gender: dates.gender,
                    id: dates.id,
                    mobilePhone: dates.mobilePhone,
                    name: dates.name,
                  });
                }
              });
            }
          });
          if (
            item.competitionSignCoachForms &&
            item.competitionSignCoachForms.length > 0
          ) {
            this.enrollInfo.push({
              collectFee: item.collectFee,
              competitionSignCoachForms: item.competitionSignCoachForms,
              feeRule: item.feeRule,
              gender: item.gender,
              id: item.id,
              itemValName: item.itemValName,
              memberFee: item.memberFee,
              signFee: item.totalFee,
              sort: item.sort,
              type: item.type,
            });
            this.feeTotal();
          }
        });
      }

      if (this.otherPeople.length > 0) {
        this.otherPeople.forEach((item) => {
          if (item.category == 2) {
            this.otherPeopleLD.push(item.name);
          } else if (item.category == 3) {
            this.otherPeopleJL.push(item.name);
          } else if (item.category == 4) {
            this.otherPeopleGZRY.push(item.name);
          } else if (item.category == 5) {
            this.otherPeopleQT.push(item.name);
          }
        });
      }
    },
    //计算费用
    feeTotal() {
      this.itemFee = { num: 0, fee: 0 };
      this.peopleFee = { num: 0, fee: 0 };
      this.enrollInfo.forEach((item) => {
        if (item.feeRule == 1) {
          this.peopleFee.num = this.peopleFee.num + Number(item.competitionSignCoachForms.length);
          this.peopleFee.fee = this.peopleFee.fee + Number(item.competitionSignCoachForms.length * (this.isMember ? item.memberFee : item.signFee));
        } else if (item.feeRule == 2) {
          this.itemFee.num = this.itemFee.num + Number(item.competitionSignCoachForms.length);
          this.itemFee.fee = this.itemFee.fee + Number(this.isMember ? item.memberFee : item.signFee);
        }
      });
    },
    //删除人员
    delPeople(row, index, rowIndex, otherIndex) {
      if (row.category == 1) {
        //运动员
        this.$confirm("您确定要删除该参赛人员吗？", "提示", {
          type: "warning",
        }).then(() => {
          if (this.tabNum == 1) {
            let competitionSignCoachForms =
              this.competitionItemFeeVos[row.index].competitionSignCoachForms;
            competitionSignCoachForms.forEach((item, idxs) => {
              if (row.id == item.id) {
                competitionSignCoachForms.splice(idxs, 1);
              }
            });
          } else if (this.tabNum == 2) {
            let itemList = this.participantData[index].itemList;
            itemList.forEach((item, idxs) => {
              if (row.itemId == item.id) {
                itemList.splice(idxs, 1);
              }
            });
          }
          this.totalData.splice(rowIndex, 1);
          this.tableKey1 = Math.random();
          this.isNext();
        });
      } else {
        //领队、教练、工作人员、其他
        this.$confirm("您确定要删除该参赛人员吗？", "提示", {
          type: "warning",
        }).then(() => {
          this.totalData.splice(rowIndex, 1);
          this.otherPeople.splice(otherIndex, 1);
          this.isNext();
        });
      }
    },
    //删除
    handleDelete(rowIndex) {
      this.$confirm("您确定要删除该人员吗？", "提示", {
        type: "warning",
      }).then(() => {
        this.tableData.splice(rowIndex, 1);
      });
    },
    //去重
    fn1(tempArr) {
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = i + 1; j < tempArr.length; j++) {
          if (tempArr[i].id == tempArr[j].id) {
            tempArr.splice(j, 1);
            j--;
          }
        }
      }
      return tempArr;
    },
    goBack() {
      this.$router.go(-1);
    },
    prev() {
      if (this.active-- == 0) this.active = 0;
      this.btnLoading = false;
      this.flag = false
      this.isPay = false
      this.isNext();
    },
    next() {
      if (this.active == 0) {
        let list = [];
        this.totalData.forEach((el) => {
          list.push({
            category: el.category,
            itemFeeId: el.itemId,
            personId: el.id,
          });
        });
        trainVerify(list).then((res) => {
          if (res.code == 200) {
            this.active++;
            this.collectEnroll();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.active == 1) {
        this.getTrainTrainPersonnelConfirmSign();
      } else if (this.active == 2) {
      } else if (this.active == 3) {
        this.active++;
        // this.confirmationApply();
      } else if (this.active > 4) {
        this.active = 0;
      }
    },
    //确认报名
    getTrainTrainPersonnelConfirmSign() {
      let trainSignPersonnelInfoForms = [];
      this.enrollInfo.forEach((item) => {
        let arr = [];
        item.competitionSignCoachForms.forEach((dates) => {
          arr.push(dates.id);
        });
        trainSignPersonnelInfoForms.push({
          personnelIds: arr,
          itemFeeId: item.id,
        });
      });
      let params = {
        teamName: this.teamName,
        trainId: this.dataInfo.id,
        trainSignPersonnelInfoForms: trainSignPersonnelInfoForms,
      };

      let data = {
        trainIds: [this.dataInfo.id],
        payChannel:1,//支付渠道：1微信 2支付宝 3银联
      }

      this.$store.commit("showLoading");
      this.btnLoading = true;

      if(!this.isClickPay){
        if(this.flag){
          if(this.isPay){
            trainPersonnelApplyOrder(data).then(res=>{
              if(this.isPay){
                this.orderNo = res.data.orderNo
                this.payVisible = true;
                this.isClickPay = true
                this.$nextTick(() => {
                  this.$refs.Pay.init(res.data.orderNo, 2);
                });
              }else {
                this.$router.push({
                  path: "/result",
                  query: {type: "success", memberPrice: "1"},
                });
              }
            }).catch(()=>{
              this.btnLoading = false
              this.$store.commit("hideLoading");
            })
          }else {
            this.$router.push({
              path: "/result",
              query: {type: "success", memberPrice: "1"},
            });
          }
        }else {
          trainTrainPersonnelConfirmSign(params).then((res) => {
            if (res.code == 200) {
              this.nextchecked = false;
              this.btnLoading = false;
              this.flag = true
              this.isPay = res.data.isPay
              if (this.isPay) {
                trainPersonnelApplyOrder(data).then(res=>{
                  if(this.isPay){
                    this.orderNo = res.data.orderNo
                    this.payVisible = true;
                    this.isClickPay = true
                    this.$nextTick(() => {
                      this.$refs.Pay.init(res.data.orderNo, 2);
                    });
                  }else {
                    this.$router.push({
                      path: "/result",
                      query: {type: "success", memberPrice: "1"},
                    });
                  }
                }).catch(()=>{
                  this.btnLoading = false
                  this.$store.commit("hideLoading");
                })
              }
            } else {
              this.nextchecked = false;
              this.btnLoading = false;
              this.$message(res.msg);
              this.$store.commit("hideLoading");
            }
          }).catch(() => {
            this.$store.commit("hideLoading");
            this.btnLoading = false;
          });
        }
      }else {
        this.$refs.Pay.init(this.orderNo, 2);
        this.btnLoading = false;
        this.nextchecked = false;
      }
      //
      // return
      // trainTrainPersonnelConfirmSign(params)
      //   .then((res) => {
      //     if (res.code == 200) {
      //       if (res.data.isPay) {
      //         this.payVisible = true;
      //         this.$nextTick(() => {
      //           this.$refs.Pay.init(res.data.orderNo, 3);
      //         });
      //       } else {
      //         this.$router.push({
      //           path: "/result",
      //           query: { type: "success", memberPrice: "0" },
      //         });
      //       }
      //       this.$store.commit("hideLoading");
      //       this.btnLoading = false;
      //     } else {
      //       this.$store.commit("hideLoading");
      //       this.btnLoading = false;
      //       return this.$message({ message: res.msg, type: "error" });
      //     }
      //   })
      //   .catch(() => {
      //     this.$store.commit("hideLoading");
      //     this.btnLoading = false;
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.steps-bar {
  width: 100%;
  height: 52px;
  padding: 14px 0;
  margin-top: 40px;
  background: url("../../assets/image/detail-signupbg.png") center no-repeat;
}

.detail-tab {
  width: 100%;
  min-height: 100px;
  color: #555;
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #e8e8e8;
  }
}
/deep/ .el-table__fixed-right::before,
/deep/ .el-table__fixed::before {
  display: none;
}
.steps-content {
  width: 100%;
  padding-top: 20px;
  .steps-content-1 {
    //background: #F2F2F2;
    //min-height: 100px;
    //padding: 10px;
  }
  //DEF0FA
  .tab_apply {
    width: 100%;
    margin-top: 10px;
    ///deep/ .el-table__header th {
    //  background: #fafafa;
    //  border-top: 1px solid #e9ecee;
    //  //padding-left: 4px;
    //  //padding-right: 4px;
    //  height: 40px;
    //  line-height: 40px;
    //  text-align: center;
    //  padding: 0;
    //  font-weight: normal;
    //}
    ///deep/ .el-table td{
    //  height: 40px!important;
    //  line-height: 40px!important;
    //  text-align: center;
    //  padding: 0!important;
    //}
  }
  .table-btn_bar {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    border-bottom: 1px solid #e9ecee;
    padding: 0 12px;
    box-sizing: border-box;
    .apply-num {
      padding-left: 16px;
      font-size: 14px;
      color: #555555;
    }
    .all-price {
      text-align: right;
      font-size: 16px;
      color: #555;
      .fz22 {
        font-size: 22px;
      }
    }
  }
}
.btn-steps {
  width: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-apply_item {
  .search-bar {
    padding-top: 0;
  }
}

.step-content-info {
  padding-left: 20px;
  line-height: 30px;
  font-size: 14px;
  color: #555;
  h2 {
    font-size: 24px;
    color: #333;
    font-weight: bold;
  }
  .fz22 {
    font-size: 22px;
  }
}

.steps-content-2,
.steps-content-3 {
  border: 1px solid #e4e4e4;
  padding: 20px;
  background: #ffffff;
}
.step-title {
  font-size: 22px;
  color: #333333;
  line-height: 26px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.step-order-info {
  background: #f8f8f8;
  padding: 10px 20px;
  margin: 20px 0;
  line-height: 34px;
  font-size: 14px;
  color: #555;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 50%;
  }
}

// 弹出层
.certificationDialog {
  .bold {
    font-weight: bold;
  }
  /deep/ .el-form-item__label {
    text-align: left;
  }
  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
}
/deep/ .el-table td {
  height: 45px !important;
  line-height: 45px !important;
  padding: 2px 0;
}
.step-detail {
  display: flex;
  padding: 20px 0;
  .step-content-image {
    width: 214px;
    height: 156px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
}
.status-price {
  color: #666;
}
.apply-tip {
  color: #999999;
  font-size: 14px;
  line-height: 32px;
}
.apply-order {
  //margin-top: 30px;
  padding: 20px 0 0 20px;
  font-size: 14px;
  //border-top: 1px solid #e8e8e8;
  color: #555555;
  line-height: 32px;
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  .name {
    display: inline-block;
    width: 128px;
  }
  .value {
    display: inline-block;
  }
}
.mt20 {
  margin-top: 20px;
}
.mt10 {
  margin-top: 10px;
}
.flex {
  display: flex;
  margin-top: 10px;
  .flex1 {
    flex: 1;
  }
}
.btn-delCertResume {
  color: #f56c6c;
}
.content-bg1 {
  background: #f2f2f2;
  padding: 10px;
  margin-bottom: 20px;
}
.content-bg2 {
  background: #def0fa;
  padding: 10px;
}
.content-bg3 {
  background: #f0f9eb;
  padding: 10px;
  margin-top: 20px;
}
.feeBox {
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
  color: #333;
  .name {
    font-size: 16px;
    margin-top: 10px;
  }
  .num {
    color: rgb(64, 158, 255);
  }
  .fee {
    color: rgba(233, 157, 66, 1);
  }
  .total {
    font-size: 20px;
    font-weight: bold;
  }
}
::v-deep .page-bar {
  margin-bottom: 0px;
  padding: 10px;
  margin-left: -22px;
  background: #fff;
}
</style>
